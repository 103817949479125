<template lang="pug">
	.add-resources.error-resource(:class="{'error-resource': error == errorTypes.ALREADY_USED}")
		.d-flex.flex-column(v-if="!error")
			.d-flex.align-items-center.mb-3.add-resources__loader
				img.table-loader.mr-3(src='/assets/img/current/loader.svg')
				span {{ $t('social.loading') }}
			span.b1.mb-2.add-resources__instuction-title {{ $t('social.addResourceInstuctionTitle') }}
			ul.d-flex.list-unstyled.flex-column.m-0.mb-2.position-relative.add-resources__list
				li.d-flex.mb-2(v-for="(item, i) in instuction" :key="`${type}-${i}`")
					.d-flex.justify-content-center.align-items-center.rounded-circle.p-2.mr-3.position-relative.z-index-1.add-resources__icon
						span.b1.text-orange {{ i + 1 }}
					p.m-0.b3 {{ item }}
			.d-flex.align-items-center.mb-4
				span.b3.add-resources__instuction-title {{ $t('resources.instuction.textMore') }}
				a.text-primary.b3.ml-1.add-resources__base-link(:href="type == types[0] ? 'https://docs.paywall.pw/rus/monetizaciya-kontenta-i-soobshestv/resursy/kak-podklyuchit-telegam-kanal-k-proektu' : 'https://docs.paywall.pw/rus/monetizaciya-kontenta-i-soobshestv/resursy/kak-podklyuchit-telegam-gruppu-k-proektu'" target="_blank") {{ $t('resources.instuction.textBase') }}
			.d-flex.align-items-center.mb-3.add-resources__copy
				span.b3 {{ $t('resources.botlogin') }}
				a.b3.ml-2.add-resources__copy-link(:href="`https://t.me/${botManagerName}`" target="_blank") @{{botManagerName}}
				pw-icon-copy.ml-auto(:text='`@${botManagerName}`' :textSuccess="`😊 &nbsp;&nbsp;&nbsp;${this.$t('resources.copyResources')}`")
			p.b3.mb-3.position-relative.add-resources__warning(v-html="$t('resources.instuction.warning')")
			.d-flex.justify-content-between.mb-md-4.add-resources__note
				p.b3.mb-0.mr-1(v-html="$t('social.botLooksLike')")
				img(v-if='isDarkColor' src='/assets/img/current/bot-dark.png')
				img(v-else src='/assets/img/current/bot.png')

		.d-flex.flex-column(v-else-if='error == errorTypes.NOT_ENOUGH_RIGHTS')
			p.b3.mb-4.position-relative.add-resources__error(v-html="errorText")
			ul.d-flex.list-unstyled.flex-column.m-0.mb-4.add-resources__permissions(v-if='permissions && permissions.length > 0')
				li.d-flex.justify-content-between.align-items-center.position-relative.add-resources__permissions-item(
					v-for="(item, i) in permissions"
					:key="`${type}-permission-${i}`"
					:class="`check-switch-${type}`"
				)
					p.m-0.b3 {{ item }}
					span.position-relative.add-resources__switch

		.d-flex.flex-column(v-else)
			p.b3.m-0.disclaimer.error
				span.b3(v-html="error")
			img.add-resources__error-img(src="/assets/img/current/error-circle.png" alt="Error")

		.span.btn.btn-border.add-resources__btn(v-if="!isMobile" @click="prev" :class='{"w-100" : error}') {{ $t('wizard.prev') }}
</template>

<script>
import { DEVICE } from '@/utils/device';
import { RESOURCE_TYPES } from '@/models/resource';
import { mapActions, mapGetters } from 'vuex';
import PwIconCopy from '@/components/Form/PwIconCopy';
import { Resource, RESOURCE_STATUSES } from '@/models/resource';

export default {
    name: 'AddResource',
    components: {
        PwIconCopy,
    },
    props: {
        type: {
            default: '',
            type: String,
        },
    },
    data: () => ({
        error: null,
        timer: null,
        added: null,
        types: _.map(RESOURCE_TYPES, v => v),
        errorTypes: RESOURCE_STATUSES,
    }),
    computed: {
        ...mapGetters({
            project: 'project/opened',
            channels: 'resources/channels',
            groups: 'resources/groups',
        }),
        instuction() {
            if (this.type == this.types[0])
                return [
                    this.$t('resources.instuctionChannel[0]'),
                    this.$t('resources.instuctionChannel[1]'),
                    this.$t('resources.instuctionChannel[2]'),
                ];

            return [
                this.$t('resources.instuctionGroup[0]'),
                this.$t('resources.instuctionGroup[1]'),
                this.$t('resources.instuctionGroup[2]'),
            ];
        },
        errorText() {
            if (this.error == RESOURCE_STATUSES.ALREADY_USED) return this.$t(`social.addErrorUsed${this.type}`);
            if (this.type == this.types[0]) return this.$t('resources.errorTextChannel');

            return this.$t('resources.errorTextGroup');
        },
        permissions() {
            if (this.error == RESOURCE_STATUSES.ALREADY_USED) return [];

            if (this.type == this.types[0]) {
                return [
                    this.$t('resources.permissionsChannel[0]'),
                    this.$t('resources.permissionsChannel[1]'),
                    this.$t('resources.permissionsChannel[2]'),
                    this.$t('resources.permissionsChannel[3]'),
                    this.$t('resources.permissionsChannel[4]'),
                    this.$t('resources.permissionsChannel[5]'),
                    this.$t('resources.permissionsChannel[6]'),
                ];
            }

            return [
                this.$t('resources.permissionsGroup[0]'),
                this.$t('resources.permissionsGroup[1]'),
                this.$t('resources.permissionsGroup[2]'),
                this.$t('resources.permissionsGroup[3]'),
                this.$t('resources.permissionsGroup[4]'),
                this.$t('resources.permissionsGroup[5]'),
                this.$t('resources.permissionsGroup[6]'),
                this.$t('resources.permissionsGroup[7]'),
            ];
        },
    },
    methods: {
        ...mapActions({
            edit: 'auth/meEdit',
            getChannel: 'resources/channelId',
            getGroup: 'resources/groupId',
            remove: 'resources/remove',
            getMe: 'auth/me',
        }),

        checkResource() {
            let method = this.type == RESOURCE_TYPES.CHANNEL ? this.getChannel : this.getGroup;

            if (!this.timer || this.timer == null) return;

            this.getMe({ noSave: true }).then(me => {
                this.findError(me);

                if (this.error) return;
                if (me.tgBotAddResource.chatId == null) return;

                clearInterval(this.timer);

                method(me.tgBotAddResource.chatId).then(item => {
                    this.$store.commit(`project/add${item.isGroup() ? 'Group' : 'Channel'}`, item);
                    this.$store.commit('resources/channels', this.project.channels);
                    this.$store.commit('resources/groups', this.project.groups);

                    this.edit({ id: this.me.id, tgBotAddResource: { projectId: null } });
                    this.$emit('close', true);
                });
            });
        },
        prev() {
            this.$emit('prev');
        },

        removeResources() {
            _.each(
                _.filter(this.added, i => !i.isActive()),
                c => this.remove({ id: c.id, type: c.type })
            );
            this.error = null;
        },

        findAddedResource(items) {
            let existed = this.type == RESOURCE_TYPES.CHANNEL ? this.project.channels : this.project.groups;
            this.added = _.filter(items, i => _.every(existed, ex => ex.id != i.id));

            if ((!existed || existed.length == 0) && items.length > 0) this.added = items;
        },

        findError(me) {
            if (me.tgBotAddResource && me.tgBotAddResource.status) {
                if (Resource.isStatusAboutRights(me.tgBotAddResource.status))
                    return (this.error = RESOURCE_STATUSES.NOT_ENOUGH_RIGHTS);
                if (Resource.isStatusAlreadyUsed(me.tgBotAddResource.status)) {
                    if (me.tgBotAddResource.duplicateProjectId)
                        return (this.error = this.$t(
                            'errors.' + (this.type == this.types[0] ? 'duplicateChannel' : 'duplicateGroup')
                        ).replace('%project%', me.tgBotAddResource.duplicateProjectTitle));
                    return (this.error = RESOURCE_STATUSES.ALREADY_USED);
                }

                return (this.error = null);
            }

            return (this.error = null);
        },
    },
    created() {
        clearInterval(this.timer);
        this.timer = setInterval(() => {
            this.checkResource();
        }, 2000);

        this.edit({ id: this.me.id, tgBotAddResource: { projectId: this.project.id } });
    },
    beforeDestroy() {
        clearInterval(this.timer);
        this.removeResources();
        this.edit({ id: this.me.id, tgBotAddResource: { projectId: null } });
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.add-resources {
    &.error-resource {
        @include respond-below(sm) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }
    }

    &__loader {
        padding: 16px;
        color: var(--primary-text-color);
        border-radius: var(--border-radius-rounded);
        background-color: var(--grayscale-bg-dark-back);
    }

    &__instuction-title {
        color: var(--grey-color);
    }

    &__list {
        &::before {
            content: '';
            position: absolute;
            top: 20px;
            left: 16px;
            width: 1px;
            height: 130px;
            background-image: url('/assets/img/icons/line.svg');

            @include respond-below(sm) {
                top: 10px;
                height: 85%;
            }
        }

        li:nth-child(3) {
            align-items: center;
        }
    }

    &__icon {
        z-index: 1;
        max-width: 35px;
        min-width: 35px;
        max-height: 35px;
        min-height: 35px;
        background-color: var(--foreground-back);
        box-shadow: var(--box-shadow-hover);
    }

    &__base-link {
        font-size: 14px !important;

        &::before {
            bottom: 2px !important;
        }
    }

    &__warning {
        padding: 16px 16px 16px 51px;
        background-color: var(--brand-bg-warning);
        border-radius: var(--border-radius-rounded);

        &::before {
            content: '';
            position: absolute;
            top: 16px;
            left: 16px;
            width: 24px;
            height: 24px;
            background: url('/assets/img/icons/info-square.svg');
            background-repeat: no-repeat;
        }

        @include respond-below(sm) {
            padding: 16px 21px 16px 51px;
        }
    }

    &__copy {
        padding: 16px 15px;
        background-color: var(--primary-bg-color);
        border-radius: var(--border-radius-rounded);
        transition: var(--animation-time-short);

        .fontello-icon-copy {
            font-size: 20px;
            color: var(--primary-text-color);
            transition: var(--animation-time-short);
            cursor: pointer;

            &:hover,
            &:active {
                color: var(--primary-dark-text-color);
            }
        }
    }

    &__copy-link {
        color: var(--primary-text-color);
        transition: var(--animation-time-short);
    }

    &__note {
        padding: 16px;
        border-radius: var(--border-radius-rounded);
        background-color: var(--grayscale-bg-dark-back);
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);

        span {
            font-size: 18px;
        }

        p {
            max-width: 383px;
        }

        img {
            display: block;
            width: 53px;
            height: 51px;
        }
    }

    &__btn:not(.w-100) {
        max-width: 154px;

        @include respond-below(sm) {
            display: none;
        }
    }

    &__error {
        padding: 16px 16px 16px 51px;
        background-color: var(--brand-bg-error);
        border-radius: var(--border-radius-rounded);

        &::before {
            content: '';
            position: absolute;
            top: 16px;
            left: 16px;
            width: 24px;
            height: 24px;
            mask-image: url('/assets/img/icons/info-square.svg');
            background-color: var(--brand-error);
            background-repeat: no-repeat;
        }

        @include respond-below(sm) {
            padding: 16px 21px 16px 51px;
        }
    }

    &__permissions {
        padding: 17px 27px;
        background-color: var(--background-color);
        border-radius: var(--border-radius-rounded);
        box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, var(--box-shadow-opacity));
    }

    &__permissions-item {
        padding: 15px;
        background-color: var(--brand-bg-primary);

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            width: calc(100% - 30px);
            height: 1px;
            background-color: var(--line-color);
            transform: translateX(-50%);
        }

        &:first-child {
            border-top-left-radius: var(--border-radius-rounded);
            border-top-right-radius: var(--border-radius-rounded);
        }

        &:last-child {
            border-bottom-left-radius: var(--border-radius-rounded);
            border-bottom-right-radius: var(--border-radius-rounded);

            &::before {
                content: none;
            }
        }

        &.check-switch-Channel {
            &:nth-child(6) {
                .add-resources__switch {
                    background-color: var(--brand-primary);

                    &::before {
                        left: auto;
                        right: 2px;
                    }
                }
            }
        }

        &.check-switch-Group {
            &:nth-child(3),
            &:nth-child(4) {
                .add-resources__switch {
                    background-color: var(--brand-primary);

                    &::before {
                        left: auto;
                        right: 2px;
                    }
                }
            }
        }
    }

    &__switch {
        width: 34px;
        height: 20px;
        border-radius: 20px;
        background-color: rgba(82, 167, 249, 0.3);

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 2px;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: var(--background-color);
            transform: translateY(-50%);
        }
    }

    &__error-img {
        display: block;
        width: 211px;
        height: 211px;
        margin: 25px auto;

        @include respond-below(sm) {
            width: 140px;
            height: 140px;
            margin: 20px auto;
        }
    }
}
</style>
